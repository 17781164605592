<template>
    <div>
        <h1>Oops! you seem lost.</h1>
    </div>
</template>
<script>
export default {
    name: "NotFound"
}
</script>
<style scoped>
    
</style>